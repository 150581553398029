import React from "react"
import { FooterCC } from "./Footer.styles"

const Footer = () => (
  <FooterCC>
    <div className="cc">© Hong Foundation</div>
  </FooterCC>
)

export default Footer
